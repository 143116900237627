'use client';

import React, { ReactNode, createContext, useMemo } from 'react';
import merge from 'deepmerge';
import { StoreThemeFragment } from '@/gql';
import { usePreviewStoreTheme } from '@/hooks/use-preview-store-theme';

export type StoreThemeState = {
  storeTheme: StoreThemeFragment;
};

export const StoreThemeContext = createContext<StoreThemeState | undefined>(undefined);

export type StoreThemeProviderProps = {
  children: ReactNode;
  storeTheme: StoreThemeFragment;
};

export const StoreThemeProvider: React.FC<StoreThemeProviderProps> = ({ children, storeTheme }) => {
  // Merge preview store theme over theme param, when present
  const previewStoreTheme = usePreviewStoreTheme();
  const memoizedTheme = useMemo<StoreThemeState>(() => {
    return { storeTheme: previewStoreTheme ? merge(storeTheme, previewStoreTheme) : storeTheme };
  }, [storeTheme, previewStoreTheme]);

  return <StoreThemeContext.Provider value={memoizedTheme}>{children}</StoreThemeContext.Provider>;
};
