'use client';

import React, { Suspense, useState, useEffect, useRef, lazy } from 'react';
import { getStoreBasicProducts } from '@/api';
import { isProductsQueryErrorResultFragment } from '@/lib/gql';
import { useCustomCollections } from '@/hooks/use-collections';
import { useStoreSlug } from '@/hooks/use-store-slug';
import { useUserPreferences } from '@/hooks/use-user-preferences';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFulfillmentDetail } from '@/lib/preferences';
import { DEFAULT_FULFILLMENT_REGION } from '@/constants';
import CollectionTileSkeleton from '@/components/CollectionTileSkeleton';

import { BasicProductsFragment } from '@/gql';
import styles from './index.module.css';

interface CustomCollectionsProps {
  collectionsToDisplay?: number;
  pageClassName?: string;
}
/**
 * Logic to determine the total number of custom collection tiles, number of tiles per row and number of products displayed per tile.
 * - By default it displays a tile for each custom collection.  collectionsToDisplay prop limits this number.
 * - Each tile usually displays one product. If there is only one custom collection, the tile on desktop displays up to 4 products.
 * - When `collectionsToDisplay` is set to 3, the component renders three tiles to span the full width of the page (homepage display).
 */

const CollectionTile = lazy(() => import('@/components/CollectionTile'));

const CustomCollections: React.FC<CustomCollectionsProps> = ({ collectionsToDisplay, pageClassName }) => {
  const { collectionsUi } = useFlags();
  const storeSlug = useStoreSlug();
  const { customCollections } = useCustomCollections();
  const { fulfillment, currency } = useUserPreferences();

  // convert fulfilment option to region
  const { region = DEFAULT_FULFILLMENT_REGION } = getFulfillmentDetail(fulfillment);
  const [productsByCollectionSlug, setProductsByCollectionSlug] = useState<Record<string, BasicProductsFragment>>({});

  let maxCollectionsToDisplay = 0;
  if (customCollections?.length) {
    maxCollectionsToDisplay =
      collectionsToDisplay && collectionsToDisplay <= customCollections.length
        ? collectionsToDisplay
        : customCollections.length;
  }

  const maxProductsToDisplay = maxCollectionsToDisplay === 1 ? 4 : 1;

  const fetchCallIdRef = useRef(0);
  useEffect(() => {
    fetchCallIdRef.current += 1;
    const currentFetchCallId = fetchCallIdRef.current;
    const fetchProducts = async () => {
      if (customCollections && customCollections.length > 0) {
        const productPromises = customCollections.map(async (collection) => {
          if (!collection.slug) {
            return null;
          }
          const response = await getStoreBasicProducts(
            storeSlug,
            1,
            maxProductsToDisplay,
            collection.slug,
            region,
            currency,
            undefined
          );
          if (response && !isProductsQueryErrorResultFragment(response) && response !== 'aborted') {
            return { slug: collection.slug, product: response };
          } else {
            return null;
          }
        });

        const products = (await Promise.all(productPromises))
          .filter((product): product is { slug: string; product: BasicProductsFragment } => product !== undefined)
          .reduce((acc, { slug, product }) => {
            acc[slug] = product;
            return acc;
          }, {} as Record<string, BasicProductsFragment>);
        if (currentFetchCallId === fetchCallIdRef.current) {
          setProductsByCollectionSlug(products);
        }
      }
    };
    fetchProducts();
  }, [customCollections, storeSlug, maxProductsToDisplay, currency, region]);

  let tilesPerRowClass = styles.tilesPerRow2;
  let maxTilesPerRow = 2;
  let mainContainerClass = styles.mainContainer;

  if (collectionsToDisplay === 3) {
    tilesPerRowClass = styles.tilesPerRow3;
    mainContainerClass = styles.mainContainerFull;
    maxTilesPerRow = 3;
  } else if (maxCollectionsToDisplay === 1) {
    tilesPerRowClass = styles.tilesPerRow1;
    maxTilesPerRow = 1;
  }

  if (!customCollections || customCollections.length === 0) {
    return null;
  }

  return (
    collectionsUi &&
    customCollections?.length > 0 && (
      <Suspense fallback={<CollectionTileSkeleton />}>
        <div
          data-testid='custom-collections-container'
          className={`${pageClassName ? styles[pageClassName] : ''} ${mainContainerClass} ${tilesPerRowClass}`}
        >
          {customCollections.map((collection, index) => {
            if (index < maxCollectionsToDisplay) {
              const collectionProducts = productsByCollectionSlug[collection.slug || ''];
              if (!collectionProducts || Object.keys(collectionProducts).length === 0) return null;
              return (
                <CollectionTile
                  key={collection.slug}
                  name={collection.name || ''}
                  url={collection.slug || ''}
                  products={[collectionProducts]}
                  maxProductsToDisplay={maxProductsToDisplay}
                  maxTilesPerRow={maxTilesPerRow}
                />
              );
            } else return null;
          })}
        </div>
      </Suspense>
    )
  );
};

export default CustomCollections;
