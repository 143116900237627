'use client';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { redirect } from 'next/navigation';
import Products, { Props as ProductsProps } from '@/components/Products';
import { useEnableNavUnderline } from '@/hooks/use-enable-nav-underline';
import { usePreviewStoreTheme } from '@/hooks/use-preview-store-theme';
import logger from '@/lib/logger';
import { ToastErrorType, isToastErrorType } from '@/lib/toast-errors';

export type Props = ProductsProps & {
  productsPropsFactory?: (previewMode?: boolean) => Promise<ProductsProps | ToastErrorType>;
};

/**
 * Products component wrapper that adds support for loading preview product data when appropriate
 *
 * @param props
 * @returns
 */
const PreviewProductsShim = (props: Props) => {
  // memo-ize divided props for variable stability
  const [productsPropsFactory, passThroughProps] = useMemo(() => {
    const { productsPropsFactory: factory, ...rest } = props;
    return [factory, rest];
  }, [props]);
  const [activeRedirectUrl, setActiveRedirectUrl] = useState<string>('');

  const [productsProps, setProductsProps] = useState(passThroughProps);

  // enable nav top underlines
  useEnableNavUnderline();

  // Use immediate mode to get the preview state on the first call (as opposed to being
  // defered to get past the first client side render) as we handle that ourselves by
  // consuming it in a useEffect()
  const previewStoreTheme = usePreviewStoreTheme(true /* immediate mode */);
  const previewMode = Boolean(previewStoreTheme?.preview);

  const inFlight = useRef(false);
  useEffect(() => {
    // If this is preview mode (and a props factory was provided), request the preview products
    if (previewMode && productsPropsFactory) {
      // Only allow one request at a time
      if (!inFlight.current) {
        inFlight.current = true;
        // Request the preview products
        productsPropsFactory(previewMode)
          .then((result) => {
            // If we got valid preview results set them in state,
            // otherwise just leave the non-preview products
            if (isToastErrorType(result)) {
              setActiveRedirectUrl(`/?err=${result}`);
            } else {
              setProductsProps(result);
            }
          })
          .catch((e) => {
            logger.warn('Failed to load preview products:', e);
          })
          .finally(() => {
            inFlight.current = false;
          });
      }
    } else {
      setProductsProps(passThroughProps);
    }
  }, [passThroughProps, previewMode, productsPropsFactory]);

  if (activeRedirectUrl) {
    return redirect(activeRedirectUrl); // should throw
  }

  return <Products {...productsProps} />;
};

export default PreviewProductsShim;
