'use client';

import { useContext } from 'react';
import { StoreThemeContext, StoreThemeState } from '@/contexts/store-theme-context';
import { StoreThemeFragment } from '@/gql';

export const useStoreTheme = (): StoreThemeFragment => {
  const context = useContext<StoreThemeState | undefined>(StoreThemeContext);
  if (!context) {
    throw new Error('Error: useStoreTheme must be used within a ThemeProvider');
  }
  return context.storeTheme;
};
