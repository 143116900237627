import React from 'react';

const CollectionTileSkeleton: React.FC = () => {
  return (
    <div
      className='mx-auto max-w-2xl px-4 mt-7 pb-7 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-7 lg:gap-y-8'
      data-testid='collection-tile-skeleton'
    >
      <div
        className='flex w-full mx-3 h-[150px] md:h-[158px] lg:h-[242px] items-center justify-between bg-[#f6f6f8] animate-pulse rounded-md mb-6 lg:mb-0'
        data-testid='collection-tile'
      >
        <div className='pl-5 h-4'>
          <div className='w-52 mb-2 h-5 bg-spring-neutral-20 animate-pulse rounded-full' />
          <div className='w-40 h-5 bg-spring-neutral-20 animate-pulse rounded-full' />
        </div>
      </div>
      <div
        className='flex w-full mx-3 h-[150px] md:h-[158px] lg:h-[242px] items-center justify-between bg-[#f6f6f8] animate-pulse rounded-md mb-6 lg:mb-0'
        data-testid='collection-tile'
      >
        <div className='pl-5 h-4'>
          <div className='w-52 mb-2 h-5 bg-spring-neutral-20 animate-pulse rounded-full' />
          <div className='w-40 h-5 bg-spring-neutral-20 animate-pulse rounded-full' />
        </div>
      </div>
    </div>
  );
};

export default CollectionTileSkeleton;
