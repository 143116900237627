'use client';

import React from 'react';
import { useUiState } from '@/stores/ui-state';
import { useCollectionsNavigation } from '@/hooks/use-collections-navigation';
import NavComponent from '@/components/NavComponent';
import styles from './index.module.css';

const NavBottom: React.FC = () => {
  const collectionsNavigation = useCollectionsNavigation(true);
  const navMode = useUiState((state) => state.navMode);

  const navMainClass = navMode === 'top' ? styles.defaultNavMain : styles.navMain;

  return (
    <NavComponent
      collectionsNavigation={collectionsNavigation}
      ariaLabel='Bottom navigation'
      navMainClass={navMainClass}
      navContentClass={styles.navContent}
      collectionActiveClass={styles.collectionActive}
      collectionDefaultClass={styles.collectionDefault}
      isSubCollectionDisplay={false}
      placement='collection_mobile_pill_homepage'
    />
  );
};

export default NavBottom;
