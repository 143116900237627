'use client';

import React from 'react';
import CustomImage from '@/components/CustomImage';
import get from 'lodash-es/get';
import { useStoreTheme } from '@/hooks/use-store-theme';
import { normalizeAssetPathUrl } from '@/lib/utils';
import { HERO_BANNER_TEMPLATE } from '@/constants';
import HeroBannerContent from '@/components/HeroBannerContent';

import styles from './index.module.css';

const HeroBanner: React.FC = () => {
  const storeTheme = useStoreTheme();

  const heroBannerBasePath = 'content.heroBanner';
  const herBannerStyles = 'styles.heroBanner';

  const heroBannerUrl = get(storeTheme, `${heroBannerBasePath}.containerBg`, '');
  const heroBannerTitle = get(storeTheme, `${heroBannerBasePath}.title`, '');
  const heroBannerSubTitle = get(storeTheme, `${heroBannerBasePath}.subtitle`, '');
  const heroBannerCtaText = get(storeTheme, `${heroBannerBasePath}.ctaText`, '');
  const heroBannerCtaLink = get(storeTheme, `${heroBannerBasePath}.ctaLink`, '#featured-products');
  const heroBannerBodyText = get(storeTheme, `${heroBannerBasePath}.body`, '');

  const heroBannerStyleTemplate = get(storeTheme, `${herBannerStyles}.template`, 0) || 0;
  const heroBannerBackgroundColor = get(storeTheme, `${herBannerStyles}.bgStyles.backgroundColor`, '');
  const heroBannerTextColor = get(storeTheme, `${herBannerStyles}.textStyles.color`);
  const heroBannerCtaBackgroundColor = get(storeTheme, `${herBannerStyles}.ctaBtnStyles.backgroundColor`, '');
  const heroBannerCtaTextColor = get(storeTheme, `${herBannerStyles}.ctaBtnStyles.color`, '');
  const storeSlug = get(storeTheme, 'storeKey', '');

  const hasContentData = heroBannerTitle || heroBannerSubTitle || heroBannerBodyText || heroBannerCtaText;

  const imageUrl = normalizeAssetPathUrl(heroBannerUrl || '', storeSlug || '');

  const heroBannerContainerClass =
    heroBannerStyleTemplate === HERO_BANNER_TEMPLATE.BASIC
      ? styles.heroBannerContainerBasic
      : styles.heroBannerContainer;

  const hasImageData = Boolean(imageUrl);

  if (heroBannerStyleTemplate === HERO_BANNER_TEMPLATE.BASIC && !hasImageData) {
    return null;
  }

  return (
    <div
      className={heroBannerContainerClass}
      style={{
        backgroundColor: heroBannerBackgroundColor,
        color: heroBannerTextColor || undefined,
      }}
      data-testid='hero-banner'
    >
      {hasContentData && heroBannerStyleTemplate !== HERO_BANNER_TEMPLATE.BASIC && (
        <HeroBannerContent
          heroBannerTitle={heroBannerTitle}
          heroBannerSubTitle={heroBannerSubTitle}
          heroBannerBodyText={heroBannerBodyText}
          heroBannerCtaLink={heroBannerCtaLink}
          heroBannerCtaText={heroBannerCtaText}
          heroBannerCtaBackgroundColor={heroBannerCtaBackgroundColor}
          heroBannerCtaTextColor={heroBannerCtaTextColor}
        />
      )}
      {imageUrl && <CustomImage src={imageUrl} alt='Hero Banner' priority fill className={styles.heroBannerImage} />}
    </div>
  );
};

export default HeroBanner;
