'use client';

import React from 'react';
import Pagination from '@/components/Pagination';
import ProductTile from '@/components/ProductTile';
// import SortComponent from '@/components/SortComponent';
import { ProductFragment } from '@/gql';
import { useProductKeyFactory } from '@/hooks/use-product-key-factory';
import styles from './index.module.css';

export interface Props {
  products: ReadonlyArray<ProductFragment>;
  page: number;
  pages: number;
}

const Products: React.FC<Props> = ({ products: productsList, page, pages }) => {
  const productKeyFactory = useProductKeyFactory();

  return (
    <section aria-labelledby='product-list' className={styles.productsDefaultContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <h2>Featured Products</h2>
          {/* <SortComponent /> */}
        </div>
        <div className={styles.productGrid}>
          {productsList.map((product) => {
            const key = productKeyFactory(product);
            return <ProductTile key={key} product={product} />;
          })}
        </div>
      </div>
      <Pagination currentPage={page} totalPages={pages} />
    </section>
  );
};

export default Products;
