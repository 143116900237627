'use client';

import React from 'react';
import Link from 'next/link';

import styles from './index.module.css';

interface HeroBannerContentProps {
  heroBannerTitle?: string;
  heroBannerSubTitle?: string;
  heroBannerBodyText?: string;
  heroBannerCtaLink?: string;
  heroBannerCtaText?: string;
  heroBannerCtaBackgroundColor?: string;
  heroBannerCtaTextColor?: string;
}

const HeroBannerContent: React.FC<HeroBannerContentProps> = ({
  heroBannerTitle,
  heroBannerSubTitle,
  heroBannerBodyText,
  heroBannerCtaLink,
  heroBannerCtaText,
  heroBannerCtaBackgroundColor,
  heroBannerCtaTextColor,
}) => {
  return (
    <div className={styles.heroBannerContentContainer}>
      <div className={styles.herBannerContent}>
        <p className={styles.heroBannerSubtitle}>{heroBannerSubTitle}</p>
        <h1 className={styles.heroBannerTitle}>{heroBannerTitle}</h1>
        <p className={styles.heroBannerText}>{heroBannerBodyText}</p>
        <div className={styles.heroBannerLinkContent}>
          <Link
            href={heroBannerCtaLink || '#featured-products'}
            className={styles.herBannerLink}
            style={{ backgroundColor: heroBannerCtaBackgroundColor, color: heroBannerCtaTextColor }}
          >
            {heroBannerCtaText}
          </Link>
        </div>
      </div>
      <div className={styles.anchor} id='featured-products' />
    </div>
  );
};

export default HeroBannerContent;
