'use client';

import React, { ReactElement, useEffect, useMemo } from 'react';
import { useStoreData } from '@/hooks/use-store-data';
import analytics from '@/lib/analytics';
import { Metrics } from '@/types/metrics';

interface AnalyticsProps {
  eventType: string;
  eventProperties?: Record<string, any>;
  children?: ReactElement;
}

const domainHost = typeof window !== 'undefined' ? window?.location?.hostname : '';

/** Component to add page view tracking to SSR pages that can't use useEffect
 * such as Home Page. New eventType should be added to Metrics type (@types/metrics)
 * and to eventKeys in an anlytics service file that will be tracking this event
 * (i.e. gtm, etc.)
 * */
const Analytics: React.FC<AnalyticsProps> = ({ eventType, eventProperties, children }) => {
  const { storeData } = useStoreData();
  const eventPropertiesCombined = useMemo(() => {
    return eventType === 'homepage_viewed' ? { ...eventProperties, domainHost } : { ...eventProperties };
  }, [eventType, eventProperties]);

  useEffect(() => {
    analytics.track(eventType as keyof Metrics, eventPropertiesCombined, storeData);
  }, [eventType, eventPropertiesCombined, storeData]);

  return children ?? null;
};

export default Analytics;
