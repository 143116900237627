'use client';

import React, { Suspense, useState, useEffect, useRef, lazy } from 'react';
import { getStoreProducts } from '@/api';
import { ProductFragment } from '@/gql';
import { isProductsQueryErrorResultFragment } from '@/lib/gql';
import { COLLECTION_PAGE_PREVIEW_ITEMS, DEFAULT_FULFILLMENT_REGION } from '@/constants';
import { getFulfillmentDetail } from '@/lib/preferences';
import { useCustomCollections } from '@/hooks/use-collections';
import { useUserPreferences } from '@/hooks/use-user-preferences';
import { useStoreSlug } from '@/hooks/use-store-slug';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CarouselSkeleton from '@/components/CarouselSkeleton';

import styles from './index.module.css';

/**
 * Renders a carousel for each collection in a store.
 *
 * This component fetches product data for each collection using the `getStoreProducts` API. It relies on several hooks for data fetching
 * and state management. Each collection's products are shown in an individual carousel, provided by the `Carousel` component.
 *
 * @component
 * @example
 * return (
 *   <CustomCollectionsCarousel />
 * )
 */

const Carousel = lazy(() => import('@/components/Carousel'));

const CustomCollectionsCarousel: React.FC = () => {
  const { collectionsExpandedTiles } = useFlags();
  const storeSlug = useStoreSlug();
  const { customCollections } = useCustomCollections();
  const { fulfillment, currency } = useUserPreferences();
  const [productsByCollectionSlug, setProductsByCollectionSlug] = useState<Record<string, ProductFragment[]>>({});

  const fetchCallIdRef = useRef(0);

  // convert fulfilment option to region
  const { region = DEFAULT_FULFILLMENT_REGION } = getFulfillmentDetail(fulfillment);

  useEffect(() => {
    const fetchCustomProducts = async () => {
      fetchCallIdRef.current += 1;
      const currentFetchCallId = fetchCallIdRef.current;

      // Await the result of Promise.all before filtering
      const fetchedProducts = (
        await Promise.all(
          customCollections.map(async (collection) => {
            if (!collection.slug) return null;

            const response = await getStoreProducts(
              storeSlug,
              1,
              COLLECTION_PAGE_PREVIEW_ITEMS,
              collection.slug,
              region,
              currency,
              undefined /* country code */
            );

            if (response && !isProductsQueryErrorResultFragment(response)) {
              return { slug: collection.slug, products: response.products || [] };
            }
            return null;
          })
        )
      ).filter(Boolean);

      if (currentFetchCallId === fetchCallIdRef.current) {
        const productsMap = fetchedProducts.reduce((acc, curr) => {
          if (curr) acc[curr.slug] = [...curr.products];
          return acc;
        }, {} as Record<string, ProductFragment[]>);
        setProductsByCollectionSlug(productsMap);
      }
    };

    if (customCollections?.length > 0) fetchCustomProducts();
  }, [customCollections, storeSlug, currency, region]);

  if (!customCollections || customCollections.length === 0) {
    return null;
  }

  return (
    collectionsExpandedTiles &&
    customCollections?.length && (
      <div>
        <Suspense fallback={<CarouselSkeleton />}>
          {customCollections?.map((collection) => {
            const currentProducts = productsByCollectionSlug[collection.slug || ''];
            return (
              <Carousel
                key={collection.slug || ''}
                name={collection.name || ''}
                nextButtonId={`${collection.slug}-next`}
                prevButtonId={`${collection.slug}-prev`}
                carouselClassName={styles.carouselContainer}
                placement='homepage'
                productData={currentProducts}
                viewAllUrl={collection.slug || ''}
                navNextClass={styles.navPadding}
                navPrevClass={styles.navPadding}
              />
            );
          })}
        </Suspense>
      </div>
    )
  );
};

export default CustomCollectionsCarousel;
