import React from 'react';
import ProductTileSkeleton from '../ProductTileSkeleton';

const CarouselSkeleton: React.FC = () => {
  return (
    <div className='bg-white' data-testid='carousel-skeleton'>
      <div className='mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 pl-6 md:px-10 pb-20'>
        <div className='h-9 w-1/3 bg-[#f6f6f8] animate-pulse rounded-lg' data-testid='heading-skeleton' />
        <div className='mt-6 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8'>
          <ProductTileSkeleton />
          <ProductTileSkeleton />
          <ProductTileSkeleton />
          <ProductTileSkeleton />
        </div>
        <div className='flex mt-5 items-center justify-center gap-2'>
          <div className='h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='pagination-dot-skeleton' />
          <div className='h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='pagination-dot-skeleton' />
          <div className='h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='pagination-dot-skeleton' />
          <div className='h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='pagination-dot-skeleton' />
          <div className='h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='pagination-dot-skeleton' />
        </div>
      </div>
    </div>
  );
};

export default CarouselSkeleton;
